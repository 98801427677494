import React from "react";
import { Helmet } from "react-helmet";

export const UxResearcherJsonLd = () => {
  const jobPostingJson = {
    "@context": "https://schema.org",
    "@type": "JobPosting",
    datePosted: "2021-01-01",
    validThrough: "2021-12-30",
    baseSalary: {
      "@type": "MonetaryAmount",
      minValue: "$",
      maxValue: "$",
      currency: "USD"
    },
    description:
      "We are looking for a full-time UX researcher for our remote agency. Take a look at the requirements and responsibilities, and if you think you're a great fit - let us know!",
    educationRequirements: ["no requirements"],
    employmentType: "Full-Time - 100% Remote",
    incentiveCompensation:
      "Performance-based annual bonus plan, project-completion bonuses",
    industry: "Computer Software",
    hiringOrganization: "Adam Fard Studio",
    jobLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        streetAddress: "Neue Schönhauser Straße 3",
        addressLocality: "Berlin",
        addressRegion: "BE",
        postalCode: "10178"
      }
    },
    applicationContact: "contact@adamfard.com",
    occupationalCategory: ["Marketing Specialist"],
    qualifications: [
      "3+ year of UX research hands-on experience",
      "English C1",
      "B2B & Complex platforms experience",
      "Working during the CET business hours",
      "Having a good knowledge of the latest UX / UI best practices and trends even though you're not designing UI directly",
      "Being a problem-solver. You love finding out you're wrong",
      "Consuming large amounts of qualitative and quantitative data to constantly refine your assumptions",
      "Communicating about any questions/problems/ideas/suggestions, asking for feedback and sharing feedback easily",
      "Being in charge of tasks, to know and tell others about ability or disability to fit the time limit",
      "Experience in leading teams is a plus"
    ],
    responsibilities: [
      "Identify business and product problems",
      "Map current user experience and journeys",
      "Design, manage and conduct qualitative and quantitative research-based upon long-term strategic needs as well as ad-hoc research requests",
      "Collect and analyze user behavior through user interviews ethnography surveys benchmark studies analytics and experiments etc.",
      "Test and validate concepts by conducting usability testing (moderated or unmoderated)",
      "Work with Designers and stakeholders to prioritize research opportunities in startups' context",
      "Understand and incorporate complex technical and business requirements into research",
      "Promote the value / ROI of user research in client's proposals and communications",
      "Advocate research findings to diverse audiences through written reports and presentations",
      "Define metrics and calculate research/design ROI during the projects"
    ],
    jobBenefits: [
      "100% Remote",
      "No micromanagement",
      "Balanced workload",
      "Access to our Talent Development Program"
    ],
    salaryCurrency: "USD",
    skills: "not required",
    specialCommitments: ["English C1"],
    title: "Join Our Team!",
    url: "https://adamfard.com/ux-researcher",
    image:
      "https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png"
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/ux-researcher/#Webpage",
    url: "https://adamfard.com/ux-researcher/",
    headline:
      "Prototypes: what are they and how they make your product better?",
    primaryImageOfPage: [
      "https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png"
    ],
    lastReviewed: "2021/01/17",
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface_design"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "We are looking for a full-time UX researcher for our remote agency. Take a look at the requirements and responsibilities, and if you think you are a great fit - let us know!",
    image:
      "https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadcrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "2",
    description:
      "We are looking for a full-time UX researcher for our remote agency. Take a look at the requirements and responsibilities, and if you think you are a great fit - let us know!",
    disambiguatingDescription:
      "For our remote agency, we're searching for a full-time UX researcher. Take a look at the qualifications and duties, and let us know if you think you'd be a good match.",
    mainEntityOfPage: "https://adamfard.com/ux-researcher/",
    image:
      "https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": "https://adamfard.com/ux-researcher",
          name: "UX Researcher"
        }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jobPostingJson)}
      </script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbJson)}
      </script>
    </Helmet>
  );
};
