import React from "react";
import {Link, graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";
import { UxResearcherJsonLd } from "../components/jsonld/UxResearcherJsonLd";

// import IconCall from "../images/icons/theme/communication/call-1.svg";
// import IconMail from "../images/icons/theme/communication/send.svg";
//
// import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
// import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
// import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
// import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
// import IconCheck from "../images/icons/interface/check.svg";

const UXresearcherPage = ({ data, location: { pathname } }) => (
  <Layout
    headerBgClass="bg-primary-alt"
    hideFooter
    footerBgClass="bg-white"
    meta={{ seo: data.datoCmsPage.seo, path: pathname }}
  >
    <UxResearcherJsonLd />

    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">
                Junior UX Researcher
                <br />
                Full-time - 100% Remote [POSITION FILLED]
              </h1>

              <p className="lead">Thank you for your interest. This position has been filled. We encourage you to check our careers page for future opportunities.</p>

              {/* <Link
                to="https://adamfard.freshteam.com/jobs/jlWESyA4FDyf/junior-ux-researcher-remote#applicant-form"
                className="btn btn-lg btn-primary lead-cta"
                target="_blank"
              >
                Apply Now
              </Link> */}

              {/* <img
                className="mb-5"
                src="https://www.datocms-assets.com/16499/1583861795-remote-ui-ux-designer-job.png"
              /> */}
              <h2 className="mt-6 mb-3">Your journey and Responsibilities</h2>
              <ul className="lead mb-5">
                <li className="mb-2">Identify business and product problems;</li>
                <li className="mb-2">Map current user experience and journeys;</li>
                <li className="mb-2">
                  Design, manage and conduct qualitative and quantitative
                  research-based upon long-term strategic needs as well as
                  ad-hoc research requests
                </li>
                <li className="mb-2">
                  Collect and analyze user behavior through user interviews,
                  ethnography, surveys, benchmark studies, analytics, and
                  experiments, etc.;
                </li>
                <li className="mb-2">
                  Test and validate concepts by conducting usability testing
                  (moderated or unmoderated);
                </li>
                <li className="mb-2">
                  Promote the value of user research in client&apos;s
                  proposals and communications;
                </li>
                <li className="mb-2">
                  Define metrics and calculate research/design ROI during the
                  projects;
                </li>
                <li className="mb-2">
                  Advocate research findings to diverse audiences through
                  written reports and presentations.
                </li>
              </ul>

              <h2 className="mt-6 mb-3">What we offer</h2>
              
              <ul className="lead mb-5">
                <li className="mb-2">Working remotely (forever);</li>
                <li className="mb-2">No micromanagement;</li>
                <li className="mb-2">Working on complex &amp; challenging products;</li>
                <li className="mb-2">Collaborate with a Senior UX researcher and Product designer. The best start for your career;</li>
                <li className="mb-2">Being part of the client facing team from the beginning;</li>
                <li className="mb-2">Talent Development program to grow professionally &ndash; We help you become a better researcher.</li>
              </ul>

              <h2 className="mt-6 mb-3">Your profile</h2>
              <ul className="lead mb-5">
                <li className="mb-2">1+ year of UX research hands-on experience;</li>
                <li className="mb-2">English C1;</li>
                <li className="mb-2">B2B & Complex platforms experience is a plus;</li>
                <li className="mb-2">Working during the US business hours (ET or PT);</li>
                <li className="mb-2">
                  Having a good knowledge of the latest UX / UI best practices
                  and trends even though you&apos;re not designing UI directly;
                </li>
                <li  className="mb-2">
                  Being a problem-solver. You love finding out you&apos;re
                  wrong;
                </li>
                <li className="mb-2">
                  Consuming large amounts of qualitative and quantitative data
                  to constantly refine your assumptions;
                </li>
                <li className="mb-2">
                  Communicating about any questions/problems/ideas/suggestions,
                  asking for feedback and sharing feedback easily;
                </li>
              </ul>

              {/* <Link
                  to="https://adamfard.freshteam.com/jobs/jlWESyA4FDyf/junior-ux-researcher-remote#applicant-form"
                  className="btn btn-lg btn-primary lead-cta"
                  target="_blank"
                >
                  Apply Now
              </Link> */}
              
            </div>

            

            {/*         
            <div className="col-12 col-md-8">
              <div className="card card--common card-body card--no-hover">
                <div id="activecampaign-form">
                  <div id="activecampaign-form__wrapper">
                    <div className=" _form_21"></div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>

    {/* {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=21")} */}
  </Layout>
);

export default UXresearcherPage;

export const query = graphql`
  query UxresearcherjuniorPage {
    datoCmsPage(slug: { eq: "junior-ux-researcher" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
